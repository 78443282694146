import Balancer from 'react-wrap-balancer'
// @ts-ignore
import { classNames } from '../utils';
import { useTranslation } from 'next-i18next'

import { motion, useMotionValue } from 'framer-motion'


import { useEffect, useRef } from 'react';
import { FADE_BOTTOM } from '../animations';
import { useDarkMode } from '../hooks/useTheme';
import { Cta } from './Cta'
import { PARTNERS } from '@/resources/constants';
import Image from 'next/image';

const Vision = "/videos/home/bg2.mp4"
const pattern = "/images/home/wave_white.svg"
const patternDark = "/images/home/wave_dark.svg"

export default function HomeHero() {
  const { t, i18n } = useTranslation();
  const { theme } = useDarkMode()

  const itemWidth = 140;  // assume each item is 200px wide
  // const visibleItemsCount = Math.floor(window.innerWidth / itemWidth);
  const totalItemsCount = PARTNERS.length * 2;  // doubled for mirroring

  // Create a doubled list of items for mirroring effect
  const allItems = [...PARTNERS, ...PARTNERS];

  const speed = 1;  // speed of the carousel
  const carouselRef = useRef(null);
  const x = useMotionValue(0);

  // Create an effect to update the x position
  useEffect(() => {
    const intervalId = setInterval(() => {
      let newX = x.get() - speed;
      if (newX < -totalItemsCount * itemWidth / 2) {
        newX = 0;  // reset to the beginning when reach the end
      }
      x.set(newX);
    }, 20);  // update every 20ms for smoothness
    return () => clearInterval(intervalId);
  }, [x]);

  return (
    <div className="isolate dark:bg-transparent bg-primary-500 h-[calc(100vh+380px)]  overflow-hidden flex md:items-center relative w-full text-foreground-primary">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#42a5f5" />
              <stop offset={1} stopColor="#3849ab" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* Overlay */}
      <div className="h-[calc(100vh+380px)]  w-screen absolute dark:opacity-75 opacity-90 bg-gradient-to-r from-transparent to-primary-500" >

        <video className="w-full h-[calc(100vh+380px)]  object-cover" src={Vision} autoPlay loop muted />
        <div className="h-[calc(100vh+380px)] w-screen absolute  z-10 bottom-0 opacity-10 dark:opacity-40 bg-black">
        </div>
        <div className="h-[calc(100vh+380px)] w-screen absolute opacity-25 dark:opacity-50  bg-gradient-to-r z-10 bottom-0 from-black via-black dark:via-black/50  to-black">

        </div>
      </div>

      <main className="w-full flex justify-center mt-32 md:-mt-[320px]" >
        <div className="relative px-6 lg:px-0 max-w-7xl ">
          <div className="mx-auto pt-10 pb-14 sm:pt-20 sm:pb-12 ">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-1 sm:gap-y-16 lg:gap-x-8 items-center " >

              <div className="text-center md:text-center p-4" >
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white">

                  <Balancer>
                    {/* Detect threatening and abnormal activities in real-time */}
                    {t("Revolutionizing")}
                    <span
                      className=" bg-gradient-to-r from-cyan-400 to-primary-500  text-transparent bg-clip-text bg-300% animate-gradient"
                    >
                      {" "}{t("Safety")}{" "}
                    </span>
                    {t("and")}
                    <span
                      className=" bg-gradient-to-r from-secondary-500 via-primary-500 to-secondary-400 text-transparent bg-clip-text bg-300% animate-gradient"
                    >
                      {" "}{t("Intelligence")}{" "}
                    </span>
                    {t("with DeepNeuronic's AI powered Technology")}


                  </Balancer>
                </h1>
                <div className="w-full flex mt-10 justify-center md:justify-center gap-2" >
                  <Cta
                    className={
                      classNames(
                      )}
                    size='large'
                  >{t("Book a demo")}</Cta>

                  {/* <Cta
                    className={
                      classNames(
                      )}
                    size='large'
                    primary={false}
                  >{t("Contact Us")}</Cta> */}
                </div>

              </div>



              {/* Pattern */}
              <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                    <linearGradient
                      id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3849ab" />
                      <stop offset={1} stopColor="#42a5f5" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

            </div>
          </div>
        </div>
      </main>

      <div className=" absolute bottom-[320px] md:px-20 w-full flex items-center justify-between dark:opacity-60" >
        <div className="carousel-container flex overflow-hidden mt-16 mx-auto ">
          <motion.div className="items flex" ref={carouselRef} style={{ x }}>
            {allItems.map((item, index) => (
              <div key={index} className="item rounded-lg shadow m-2 flex items-center justify-center md:min-w-[140px] min-w-[120px] ">
                <Image height={64} width={140} className="invert grayscale md:h-16 h-12 w-auto object-contain" src={item} alt="" />
              </div>
            ))}
          </motion.div>
        </div>

      </div>

      <div className="absolute bottom-[50px] text-center p-12 w-full  lg:w-[900px] z-20 lg:left-1/2 lg:-ml-[450px]" >
        <motion.h2
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
          className="text-3xl font-semibold leading-tight sm:text-4xl lg:text-5xl tracking-tight w-full text-center " >{t("home_feature_title")}
        </motion.h2>

        <motion.h3
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.5
          }}
          viewport={{ once: true }}
          className="mt-4 text-base lg:text-xl font-bold tracking-tight text-foreground-secondary text-center">{t("home_feature_subtitle")}
        </motion.h3>

      </div>

      <div className="absolute min-w-[1600px] min-h-[300px] max-h-[360px] overflow-y-hidden z-50 left-0 bottom-0 w-full -mb-[80px] "
      >
        <Image width={100} height={360} className="h-[360px] w-full object-cover" src={theme === "dark" ? patternDark : pattern} alt="" />
      </div>
    </div>
  )
}
